import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import "./App-responsive.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Home from './Home';
// import Privacy from './Components/Privacy/Privacy';
import OurTerms from './Components/Terms/OurTerms';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

function App() {
  
  const driverObj = driver({
    popoverClass: 'driverjs-theme',
    showProgress: true,
    steps: [
      { element: '.Header-section', popover: { title: 'Header section of website', description: 'The header section contains the logo and the nav links. You can click on the links to scroll through them' } },
      { element: '.scroll-to-top', popover: { title: 'Scroll to the top', description: 'Click on the button to scroll to the to of the website' } },
      { element: '.serviceCards', popover: { title: 'Services Rendered', description: 'Read through our services to understand what we offer.' } },
      { element: '.container-card', popover: { title: 'Meet our team of Testers', description: 'Meet of team of experts to help you meet your needs' } },
      { element: '.fa-linkedin', popover: { title: 'Follow on LinkedIn', description: 'Find us on LinkedIn' } },
      { element: '.contactButton', popover: { title: 'Contact via Email ', description: 'Click on the button to contact us via email' } },
      { element: '.Footer', popover: { title: 'Footer section of website', description: 'The footer section contains link to the terms and also navigation links' } },
    ]
  });
  
  driverObj.drive();
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  return (
    <div className="App">
      {/* Conditionally render Header and Footer */}
      {!isHomePage && <Header />}
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/terms" element={<OurTerms />} />
        {/* <Route path="/privacy" element={<Privacy />} /> */}
      </Routes>
      
      {!isHomePage && <Footer />}
    </div>
  );
}

export default App;
