import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import ReactMarkdown from 'react-markdown'
import { Helmet } from 'react-helmet';

const TITLE = 'OffensiveHex | Terms and Conditions';

 
const OurTerms = () => {

  const TermsMD = `
## **Terms and Conditions**

Welcome to [www.offensivehex.com](https://offensivehex.com/). By accessing or using our website, you agree to comply with and be bound by the following terms and conditions. If you do not agree to these terms, please do not use our site.

**1. No Account Creation**:
We do not offer or require the creation of user accounts on www.offensivehex.com. All users are free to access the site and its content without the need for registration.

**2. No Subscription Plans**:
Currently, www.offensivehex.com does not offer any subscription plans or paid services. All content and services provided through the site are available without charge.

**3. Intellectual Property Rights**:
All content, trademarks, logos, and other intellectual property displayed on www.offensivehex.com are the exclusive property of their respective owners. This includes, but is not limited to, text, graphics, logos, icons, and images.

Users are not permitted to use, reproduce, or distribute any content or trademarks from this site without prior written permission from the respective owners. Unauthorized use may result in legal action.

**4. User Content**:
Any content submitted by users, such as comments or feedback, is considered non-confidential and non-proprietary. By submitting content, you grant www.offensivehex.com a non-exclusive, royalty-free, perpetual, and irrevocable license to use, reproduce, modify, publish, and display such content.

**5. Disclaimer of Warranties**:
The content on www.offensivehex.com is provided "as is" without any warranties of any kind, either express or implied. We do not warrant that the site will be available at all times, or that the information provided is accurate, complete, or current.

**6. Limitation of Liability**
www.offensivehex.com and its owners shall not be liable for any damages arising from the use of the site, including but not limited to direct, indirect, incidental, punitive, and consequential damages.

**7. Changes to Terms and Conditions**:
We reserve the right to update or modify these Terms and Conditions at any time without prior notice. By continuing to use the site after changes are made, you agree to be bound by the updated terms.

**8. Governing Law**:
These terms and conditions are governed by and construed in accordance with the laws of Czech Republic, and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.

**Contact Information**:
For any questions or concerns regarding these terms, please contact us at [info@offensivehex.com](mailto:info@offensivehex.com).

  `
  return (
        <div class="container-terms">
           <Helmet>
              <title>{ TITLE }</title>
            </Helmet>
           <ReactMarkdown>{TermsMD}</ReactMarkdown>
        </div>

  )
}

export default OurTerms