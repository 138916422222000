import React from 'react'
import s1 from '../../assets/picture1.jpg'
import s2 from '../../assets/picture2.jpeg'


const OurTeam = () => {
    const  nameVariable = {
        name1: "Christian Bortone", 
        name2: "Jaroslav Priščák",
        position: "Sr. Peneration Tester",
        username1: "@christianbortone",
        username2: "@jaroslavpriščák",
        linkedinUrl1: "https://www.linkedin.com/in/christianbortone/",
        linkedinUrl2: "#"
    }
  return (
    <section className='ourTeam'>
        <div className='container'>
            <h2>Our Team</h2>
            <p>
                Welcome to OffensiveHex, We are a dedicated team of security experts specializing in penetration testing to help businesses identify and mitigate vulnerabilities in their digital infrastructure.
            </p>
            <div class="container-card">
            <div class="card">
                <img src={s1} alt="Christian Bortone"  />
                <br />
                <h1>{nameVariable.name1}</h1>
                <p class="title">{nameVariable.position}</p>
                <div className='socialLink'>   
                    <a href={nameVariable.linkedinUrl1} target='_blank' rel="noopener noreferrer"> <i class="fab fa-linkedin"><span>{nameVariable.username1}</span></i></a>   
                </div>
            </div>

            <div class="card">
                <img src={s2} alt="Jaroslav Priščák"  />
                <br />
                <h1>{nameVariable.name2}</h1>
                <p class="title">{nameVariable.position}</p>
                <div className='socialLink'>   
                    <a href={nameVariable.linkedinUrl2} target='_blank' rel="noopener noreferrer"> <i class="fab fa-linkedin"><span>{nameVariable.username2}</span></i></a>   
                </div>
            </div>
            </div>
        </div>

    </section>
  )
}

export default OurTeam