import React from 'react';
import { Element } from 'react-scroll';

const ContactUs = () => {
  return (
    <Element className='contactSec section' name="contactus">
      <div className="container">
        <div className="contactUsContainer">
          <h2>Contact Us Today!</h2>
          <p>
            We are committed to helping you stay one step ahead of cyber threats. Contact us today to learn how we can help secure your digital assets and protect your business.
          </p>
          <button className='contactButton'>
            <a  target="_blank" rel="noopener noreferrer" href="mailto:info@offensivehex.com">Contact Us</a>
          </button>
        </div>
      </div>
    </Element>
  );
}

export default ContactUs;
