import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import logo from '../../assets/logo.png';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const Header = () => {
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
      <Container className='Header-section'>
        <Navbar.Brand as={RouterLink} to="/">
          <img className='image-logo' src={logo} alt="logo" /> 
          {/* <span className='logo-title'>OffensiveHex</span> */}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className='justify-content-end'>
          <Nav className="ml-auto"> {/* Use ml-auto to push the links to the right */}
            <Nav.Link as={ScrollLink} to="services"  className="mx-3">
              Services
            </Nav.Link>
            <Nav.Link as={ScrollLink} to="about"  className="mx-3">
              About Us
            </Nav.Link>
            <Nav.Link as={ScrollLink} to="contactus"  className="mx-3">
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
