import React from 'react'
import { Link } from 'react-scroll';
import { Link as Routelink } from 'react-router-dom';

const Footer = () => {
    const siteFooter = {
        year: "2024",
        navFooter1: "Terms",
        navFooter2: "Privacy",
        navFooter3: "About Us",
        navFooter4: "Contact Us"

    }
  return (
    <nav class="navbar navbar-expand-lg Footer">
        <div class="container">
            <Routelink class="navbar-brand nav-brand-footer" to="/"> <span>OffensiveHex</span> </Routelink> <span>@ {siteFooter.year}. All rights reserved.</span>
            <div class="justify-content-end" id="navbarSupportedContent">
                <ul class="navbar-nav nav-footer mb-2 mb-lg-0">
                    <li class="nav-item">
                        <Routelink class="nav-link active" aria-current="page" to="/terms">{siteFooter.navFooter1} |</Routelink>
                    </li>
                    {/* <li class="nav-item">
                        <Routelink class="nav-link active" aria-current="page" to="/privacy"> {siteFooter.navFooter2} |</Routelink>
                    </li> */}
                    <li class="nav-item">
                        <Link class="nav-link" to="about"> {siteFooter.navFooter3} |</Link>
                    </li>
                    <li class="nav-item">
                        <Link class="nav-link" aria-disabled="true" to="contactus"> {siteFooter.navFooter4}</Link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
  )
}

export default Footer